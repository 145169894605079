<template>
  <div id="page-under-construction">
    <Headline :text="$t('page_under_construction.heading')" />
    <v-row>
      <v-col class="col-12">
        <p>{{ $t('page_under_construction.first_row') }}</p>
        <p>&nbsp;</p>
        <p>
          <PrimaryButton :href="this.link" target="_blank">{{ this.linkText }}</PrimaryButton>
        </p>
      </v-col>
      <v-col class="col-12">
        <img alt="Under construction" src="@/assets/images/robot.png" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import i18n from '../../i18n'
import Headline from '@/components/app/Headline.vue'
import PrimaryButton from '@/components/app/PrimaryButton.vue'

export default {
  components: {
    PrimaryButton,
    Headline
  },
  props: {
    link: {
      String,
      default: '/'
    },
    linkText: {
      String,
      default: i18n.t('misc.dashboard')
    },
    external: {
      Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass">
#page-under-construction
  text-align: center

  img
    max-width: 500px
    width: 70%
</style>
