<template>
  <div id="blocked-adult-check">
    <BlockedAdultCheckIndex />
  </div>
</template>

<script>
import BlockedAdultCheckIndex from '@/components/tools/blocked-adult-check/BlockedAdultCheckIndex'

export default {
  components: {
    BlockedAdultCheckIndex
  }
}
</script>
